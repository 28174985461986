
import { qrPubCssTempList } from '@/api/template'
import { txtQrImg,getPromptPerson,getPromptEnv,getPromptScene } from '@/api/ai/sd.js'
import eventBus from '@/utils/event-bus'

export default {
  name : 'BeautifyQr',
  components : {},
  props : {
    baseImg : String,
    qrText : String
  },
  data() {
    return {
      idisCode : '',
      isEdit : false,
      qrData : this.qrText,
      qrBaseImg : this.baseImg,
      downLoadOrSaveVisible : false,
      upBgImg : '',
      upBgImgOp : 0.5,
      detectSpecial : false,
      bgOpacity : 1,
      eyeModel : '',
      qrParam : {},
      showBeautifyDialog : false,
      prompt: '',
      promptLabels: {
        person: [],
        env: [],
        scene: []
      },
      loading: false
    }
  },
  computed : {},
  created() {
    getPromptPerson().then( res => {
      this.promptLabels.person = res.rows
    } )
  },
  mounted() {
    const _this = this
    this.downLoadSet = JSON.parse( localStorage.getItem( 'downLoadSet' ) ) ? JSON.parse( localStorage.getItem( 'downLoadSet' ) )
      : {
        size : 300,
        type : 'png'
      }
  },
  methods : {
    genAiQrCode(){
      let base64Img = this.baseImg
      if (base64Img.startsWith('data:image/png;base64,' )) {
        base64Img = base64Img.split(',')[1]; // 提取 base64 数据部分
      } else {
        this.$message( {
          type : 'error',
          duration : '5000',
          message : '二维码图片获取失败'
        } )
        return
      }
      this.loading = true
      txtQrImg({
        prompt: this.prompt,
        base64Img: base64Img
      }).then(res => {
        this.loading = false
        this.qrBaseImg = 'data:image/png;base64,' + res.data
      }).catch(() => {
        this.loading = false
      })
    },
    openLogin() {
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    openDialog( idisCode ) {
      this.showBeautifyDialog = true
      this.qrData = this.qrText
      this.qrBaseImg = this.baseImg
      this.loading = false
      this.prompt = ''
      if ( idisCode ) {
        this.idisCode = idisCode
      } else {
        this.idisCode = ''
      }
      // this.$nextTick( () => {
      //   document.getElementById( 'mySection' ).style.overflowY = 'hidden'
      // } )
    },
    handleQrClose() {
      this.showBeautifyDialog = false
      document.getElementById('mySection').style.overflowY = 'auto'
    },
    qrPubCssTempList() {
      qrPubCssTempList().then( res => {
        this.pubQrList = res.rows
      } )
    },
    downloadQr() {
      const aLink = document.createElement( 'a' )
      aLink.style.display = 'none'
      aLink.href = this.qrBaseImg
      aLink.download = '二维码.png'
      document.body.appendChild( aLink )
      aLink.click()
      document.body.removeChild( aLink )
    },
    getQrBase64() {
    },
    enterPrompt(prompt){
      this.prompt = prompt
      this.genAiQrCode()
    }
  }
}

